<template>
  <div>
    <div class="bottom_block"></div>
    <!-- 底部功能 -->
    <div class="m_bottom">
      <div class="m_bottom_body">
        <div class="m_bottom_body-list" @click="clihome">
          <div
            v-if="ub_index == 2"
            class="m_icon-1_bo"
            :class="
              $route.path == '/mhome' || $route.path == '/mhome.html' || $route.path == '/'
                ? 'm_icon-1-act_bo'
                : ''
            "
          ></div>
          <div
            v-else
            class="m_icon-1"
            :class="
              $route.path == '/mhome' || $route.path == '/mhome.html' || $route.path == '/'
                ? 'm_icon-1-act'
                : ''
            "
          ></div>
          <a>首页</a>
        </div>
        <div
          class="m_bottom_body-list"
          @click="YS.routetogo({ path: '/mrecord', query: { isrec: false } })"
        >
          <div
            class="m_icon-2"
            :class="
              $route.path == '/mrecord' || $route.path == '/mrecord.html' ? 'm_icon-2-act' : ''
            "
          ></div>
          <a>记录</a>
        </div>
        <!-- <div class="m_bottom_body-list" @click="YS.routetogo({ path: '/mvip' })">
          <div class="m_icon-3"></div>
          <a>充值</a>
        </div> -->
        <div class="m_bottom_body-list" @click="YS.routetogo({ path: '/mmess' })">
          <div
            class="m_icon-4"
            :class="$route.path == '/mmess' || $route.path == '/mmess.html' ? 'm_icon-4-act' : ''"
          ></div>
          <a>帮助</a>
        </div>
        <div class="m_bottom_body-list" @click="cliuser()">
          <div
            class="m_icon-5"
            :class="
              $route.path == '/mcenter' || $route.path == '/mcenter.html' ? 'm_icon-5-act' : ''
            "
          ></div>
          <a>我的</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
export default {
  name: 'Mbottom',
  data() {
    return {
      ub_index: 1,
      user: {},
      tokenval: {},
      menutotal: [],
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
    this.ub_index = window.g.ub_index;
  },
  computed: {
    islogin: () => {
      return that.$store.state.islogin;
    },
  },
  async mounted() {
    that.user = JSON.parse(localStorage.getItem('user'));
    that.tokenval = JSON.parse(localStorage.getItem('token'));
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore) {
      if (menutotalstore.time > new Date().getTime()) {
        //that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
        that.menutotal = menutotalstore.data;
      }
    }

    //获取菜单栏
    if (!that.menutotal[0]) {
      try {
        let data = await that.api.user.api_categoryhome({});
        data.category_list &&
          data.category_list.forEach((e) => {
            that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });
            if (e.pid == 0) {
              e.list = [];
              that.menutotal.push(e);
            } else {
              that.menutotal.forEach((a, i) => {
                if (a.id == e.pid) {
                  that.menutotal[i].list.push(e);
                }
              });
            }
          });

        //存储至本地
        that.YS.savestore('menutotal', that.menutotal, 600);
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    //《我的》点击事件
    cliuser() {
      that.user = JSON.parse(localStorage.getItem('user'));
      that.tokenval = JSON.parse(localStorage.getItem('token'));
      if (
        !that.tokenval ||
        !that.user ||
        that.tokenval.expired_time < Date.parse(new Date()).toString().substr(0, 10)
      ) {
        that.YS.routetogo({ path: '/mlogin' });
      } else {
        that.YS.routetogo({ path: '/mcenter' });
        if (that.$parent.mcendg) {
          that.$parent.todialog('center');
        }
      }
    },
    //首页点击
    clihome() {
      that.$store.commit('mhome', true);
      that.YS.routetogo({ path: '/mhome' });
    },
  },
};
</script>
