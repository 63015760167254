// 移动端首页
<template>
  <div class="m_total" id="mtotal">
    <!-- 置顶top -->
    <input
      type="password"
      class="form-control"
      name="SecretOld"
      style="position: absolute; top: -20px; left: 0; width: 1px; height: 1px"
    />
    <Mtop ref="mtop"></Mtop>
    <div v-if="$store.state.islogin">
      <div v-show="$store.state.mhome">
        <transition name="mcenter_3">
          <div v-show="enter">
            <!-- <div
            class="m_home_block"
            :class="{ 'm_home_block-nonelant': !$store.state.mhomeshow.lant }"
          ></div> -->
            <div
              class="m_home_block_black"
              :class="{ 'm_home_block_black-lant': $store.state.mhomeshow.lant }"
              v-show="$store.state.mhomeshow.lant"
            ></div>
            <div class="m_home_block_black-nonelant" v-show="!$store.state.mhomeshow.lant"></div>
            <!-- 走马灯 -->
            <div class="lantern swiper-container1" v-show="$store.state.mhomeshow.lant">
              <div class="lantern_img"></div>
              <!-- <span>这是一段跑马灯消息</span> -->
              <div class="marquee_0">
                <div class="marquee_1">
                  <span class="marquee_a" v-for="(item, index) in notice_list" :key="index">
                    {{ item.title }}
                  </span>
                </div>
              </div>
              <!-- <marquee>
              <a class="marquee_a" v-for="(item, index) in notice_list" :key="index">
                {{ item.title }}
              </a>
            </marquee> -->
            </div>
            <div
              class="m_top-title_block"
              :class="{ 'm_top-title_block-lant': $store.state.mhomeshow.lant }"
            ></div>
            <!-- 标签标题 -->
            <div
              class="m_top-title"
              :class="{
                'm_top-title-nonelant': !$store.state.mhomeshow.lant,
                'm_top-title-lant': $store.state.mhomeshow.lant,
              }"
              v-show="$store.state.mhomeshow.title"
            >
              <div class="m_top-title-left">
                <div
                  class="m_top-title-list"
                  :class="{ 'm_top-title-list-act': titleindex == 0 }"
                  @click="climtopTitle(null, 0, true)"
                >
                  <a>首页推荐</a>
                  <div class="m_under"></div>
                </div>
                <template v-for="(item, index) in menutotal">
                  <!-- v-if="item.show" -->
                  <div
                    :key="index"
                    class="m_top-title-list"
                    :class="{ 'm_top-title-list-act': titleindex == index + 1 }"
                    @click="climtopTitle(item.id, index + 1, false, item.symbol, item.name)"
                  >
                    <a>{{ item.name }}</a>
                    <div class="m_under"></div>
                  </div>
                </template>
              </div>

              <!-- <div class="m_top-title-list-block"></div> -->

              <div
                @click="cliTopDowm(titleindex)"
                class="m_top-title_down"
                :class="{
                  'm_top-title_down-act': topDowm || titleindex > 0,
                  'm_top-title_down-lant': $store.state.mhomeshow.lant,
                }"
              >
                <div class="topdownBack"></div>
              </div>
            </div>
            <!-- 分类筛选 -->
            <div class="m_fil" v-show="(filindex || topDowm) && $store.state.mhomeshow.fil">
              <!-- 联动二级分类筛选 -->
              <div class="mfilter" v-if="menutotal[filindex - 1]">
                <a
                  :class="{ 'mfilter-act': !menu_fu_act || menu_fu_act == 0 }"
                  class="fil_box category_id_fil fil_fenlei"
                  @click="changecategory_id(0, null)"
                >
                  全部分类
                </a>
                <a
                  v-for="(item, index) in menutotal[filindex - 1].list"
                  :key="index"
                  :class="{ 'mfilter-act': menu_fu_act - 1 == index }"
                  class="fil_box category_id_fil fil_fenlei"
                  @click="changecategory_id(index + 1, item.id)"
                >
                  {{ item.name }}
                </a>
              </div>
              <template v-for="(item, key) in ccc1">
                <div
                  class="mfilter_body_list"
                  :key="key"
                  v-if="(key !== 'mosaic' && key !== 'tags') || !isnormal"
                >
                  <div
                    :class="[key + '_fil', { 'mfilter-act': ccc2[key] == null || ccc2[key] < 0 }]"
                    class="fil_box"
                    @click="changerList(key, null)"
                  >
                    <a class="fil">全部{{ lang[key] }}</a>
                  </div>
                  <div
                    :class="[key + '_fil', { 'mfilter-act': ccc2[key] == index }]"
                    class="fil_box"
                    v-for="(value, index) in item"
                    :key="index"
                    @click="changerList(key, index)"
                  >
                    <a class="fil">
                      {{ value }}
                    </a>
                  </div>
                </div>
              </template>
              <transition name="mcenter_11">
                <div class="mfilbox" v-show="video_enter">
                  <div
                    class="filbox_list1"
                    v-for="(item, index) in video_list"
                    v-show="item"
                    :key="index"
                  >
                    <!-- @click="clickSearchList(item.id)" -->
                    <!-- <transition name="mcenter_h"> -->
                    <a
                      class="mhome_aimg"
                      :title="item.title"
                      :href="
                        YS.returnURL(
                          {
                            path: '/mplay',
                            query: { video_id: item.id },
                          },
                          true,
                        )
                      "
                    >
                      <img
                        class="filbox_img1"
                        :alt="item.title"
                        :src="item.pic"
                        :onerror="
                          ub_index == 2 ? $store.state.defaultimg_bo : $store.state.defaultimg
                        "
                      />
                      <transition name="mhome_list_bottom">
                        <div class="mfilbox_bottom" v-show="item.picLoad">
                          <div class="mfilbox_bottom-line">
                            <div class="mfilbox_bottom-title">
                              <span v-if="isShowClassification(item.parent_category_id)">
                                {{ getClassification(item.parent_category_id, item.category_id) }}
                              </span>
                              <span v-else-if="item.multiple_fragment && item.state == '更新中'">
                                更新
                              </span>
                              <span v-else-if="item.multiple_fragment && item.state == '已完结'">
                                完结
                              </span>
                              <span v-else>{{ item.state }}</span>
                            </div>
                            <div class="mfilbox_bottom-num" v-show="item.multiple_fragment">
                              {{ item.last_fragment_symbol }}
                            </div>
                            <div class="mfilbox_bottom-fraction" v-show="item.score">
                              {{ item.score.toFixed(1) }}
                            </div>
                          </div>
                        </div>
                      </transition>
                    </a>
                    <!-- </transition> -->
                    <a
                      class="filbox_span"
                      :href="
                        YS.returnURL(
                          {
                            path: '/mplay',
                            query: { video_id: item.id },
                          },
                          true,
                        )
                      "
                    >
                      <span>{{ item.title }}</span>
                    </a>
                  </div>
                </div>
              </transition>
              <!-- 没有您搜索的内容-没有数据时 -->
              <div class="msearch_none2" v-show="!video_list && video_none">
                <img src="../../assets/img/m_none2.0@2x.png" alt="没有搜索到内容" />
                <a>没有您搜索的内容....</a>
              </div>
              <div class="msearch_none4" v-show="!video_list && loading">
                <img
                  class="load0"
                  src="../../assets/img/loading.png"
                  height="100"
                  width="100"
                  alt="加载图片"
                />
              </div>
              <!-- 加载样式 -->
              <div class="bars-7" v-show="!istobottom && isloading"></div>
              <!-- <div class="bars-7"></div> -->
              <!-- 底线 -->
              <div class="istobottom" v-show="istobottom">
                <img src="../../assets/img/dixian.png" alt="底部底线" />
                <div class="istobottom2">已经到最底部了</div>
              </div>
              <div v-show="!istobottom" class="bottom_blocks"></div>
            </div>
            <!-- 轮播 -->
            <div class="m_swipe" v-show="$store.state.mhomeshow.swipe">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    :data-url="item.url"
                    v-for="(item, index) in banner_list"
                    :key="index"
                  >
                    <img width="100%" class="mswiper_img" :src="item.source" />
                  </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
              </div>
            </div>
            <!-- 广告栏 -->
            <div
              class="m_guanggao"
              v-if="guanggaolist && guanggaolist['mobile-home-banner-below']"
              @click="cliguanggao('mobile-home-banner-below')"
              v-show="$store.state.mhomeshow.guanggao"
              :style="{
                backgroundImage: 'url(' + guanggaolist['mobile-home-banner-below'][0].source + ')',
              }"
            ></div>
            <div
              class="m_guanggao m_guanggao2"
              v-if="guanggaolist2 && guanggaolist2['mobile-home-banner-below-bottom']"
              @click="cliguanggao2('mobile-home-banner-below-bottom')"
              :style="{
                backgroundImage:
                  'url(' + guanggaolist2['mobile-home-banner-below-bottom'][0].source + ')',
              }"
            ></div>
            <!-- 大分类-列表栏 -->
            <div v-show="$store.state.mhomeshow.movie" id="bigfor">
              <template v-for="(item, index) in menutotal">
                <div
                  class="m_model"
                  v-if="video_latest_list[item.id] && item.symbol !== 'night'"
                  :key="index"
                >
                  <!-- 头部 -->
                  <div class="m_model_title">
                    <div class="m_model_title-left">
                      <img
                        v-if="ub_index == 2"
                        style="height: 24px; width: 24px"
                        src="../../assets/bo/list1.png"
                        alt="大分类logo图标"
                      />
                      <img v-else src="../../assets/img/m_icon@2x.png" alt="大分类logo图标" />
                      <a>{{ item.name }}</a>
                    </div>
                    <div class="m_model_title-right" @click="climtopTitle(item.id, index + 1)">
                      <a>更多</a>
                      <div>
                        <img src="../../assets/img/toRight.png" alt="前往大分类筛选" />
                      </div>
                      <!-- <div class="mhome_right_icon">》</div> -->
                    </div>
                  </div>
                  <!-- 列表 -->
                  <!-- <transition name="mcenter_11"> -->
                  <div class="m_model_list" v-if="video_latest_list[item.id]">
                    <!-- 遍历循环列表 -->
                    <!-- <div
                    class="m_model_list-child"
                    v-for="(value, keyi) in video_latest_list[item.id].slice(0, 4)"
                    :key="keyi"
                    @click="YS.routetogo({ path: '/mplay', query: { video_id: item.id } })"
                  >
                    <div
                      class="m_model_list-child-img"
                      :style="{ backgroundImage: 'url(' + value.pic + ')' }"
                    >
                      <div class="m_model_list-child-font">
                        <span>{{ $store.state.menudist[item.category_id] }}</span>
                        <a>{{ item.score }}</a>
                      </div>
                    </div>
                    <a class="m_model_list-title">{{ value.title }}</a>
                  </div> -->
                    <template v-for="(item, index) in video_latest_list[item.id]">
                      <div class="filbox_list1" v-if="index < 9" :key="index">
                        <!-- @click="clickSearchList(item.id)" -->
                        <a
                          class="mhome_aimg"
                          :title="item.title"
                          :href="
                            YS.returnURL(
                              {
                                path: '/mplay',
                                query: { video_id: item.id },
                              },
                              true,
                            )
                          "
                        >
                          <img
                            class="filbox_img1"
                            :src="item.pic"
                            :onerror="
                              ub_index == 2 ? $store.state.defaultimg_bo : $store.state.defaultimg
                            "
                            :alt="item.title"
                          />
                          <!-- <span v-show="!item.multiple_fragment && item.duration">
                          {{ YS.tomin(item.duration) }}
                        </span>
                        <span v-show="item.multiple_fragment && item.state == '更新中'">
                          更新至{{ item.last_fragment_symbol }}集
                        </span>
                        <span v-show="item.multiple_fragment && item.state == '已完结'">
                          {{ item.last_fragment_symbol }}集全
                        </span> -->
                          <transition name="mhome_list_bottom">
                            <div class="mfilbox_bottom" v-show="item.picLoad">
                              <div class="mfilbox_bottom-line">
                                <div class="mfilbox_bottom-title">
                                  <span v-if="isShowClassification(item.parent_category_id)">
                                    {{
                                      getClassification(item.parent_category_id, item.category_id)
                                    }}
                                  </span>
                                  <span
                                    v-else-if="item.multiple_fragment && item.state == '更新中'"
                                  >
                                    更新
                                  </span>
                                  <span
                                    v-else-if="item.multiple_fragment && item.state == '已完结'"
                                  >
                                    完结
                                  </span>
                                  <span v-else>{{ item.state }}</span>
                                </div>
                                <div class="mfilbox_bottom-num" v-show="item.multiple_fragment">
                                  {{ item.last_fragment_symbol }}
                                </div>
                                <div class="mfilbox_bottom-fraction" v-show="item.score">
                                  {{ item.score.toFixed(1) }}
                                </div>
                              </div>
                            </div>
                          </transition>
                        </a>
                        <!-- <span v-show="item.score" class="fraction_mhome">
                        {{ item.score.toFixed(1) }}
                      </span> -->
                        <a
                          class="filbox_span"
                          :href="
                            YS.returnURL(
                              {
                                path: '/mplay',
                                query: { video_id: item.id },
                              },
                              true,
                            )
                          "
                          :title="item.title"
                        >
                          <span>{{ item.title }}</span>
                        </a>
                      </div>
                    </template>
                  </div>
                  <!-- </transition> -->
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <transition name="mhome_bottom">
        <Mbottom v-show="!$store.state.mtopfloating.top"></Mbottom>
      </transition>
      <!-- WIN 隐藏版密码校验密码 -->
      <transition name="mcenter_8">
        <div class="mpayresult1 mpayresult_hidden" v-show="hiddenPass">
          <input type="password" class="form-control" style="display: none" />
          <!-- <div class="mpayresult1" v-show="true"> -->
          <div class="mpay_hidden_title" :class="{ hiddenColor: hiddenError }">
            {{ hiddenError ? '密码错误请重新输入' : '请输入午夜版密码' }}
          </div>
          <div class="payrbody_hidden-box">
            <input
              maxlength="20"
              v-model="hiddenValue"
              type="password"
              placeholder="初始密码1818，请前往安全设置中更改"
              @keydown.enter="checkHidden"
            />
          </div>
          <div class="payrbody_hidden" :class="{ cliloading: loadCli }" @click="checkHidden">
            <span v-show="!loadCli">确认</span>
            <div class="dots-6" v-show="loadCli"></div>
          </div>
          <img
            class="login-close"
            @click="openandclo('hiddenPass', false)"
            src="../../assets/img/close.png"
            alt="关闭视频报错弹窗"
          />
        </div>
      </transition>
      <!-- WIN 需要登录 -->
      <div class="mpayresult" v-show="mustlogin">
        <div class="payrbody7">
          <img src="../../assets/img/mustvip.png" alt="需要登录用户提示图片" />
          <span>非常抱歉，本功能需要登录用户才能体验哦！</span>
        </div>
        <div class="payrbody2">
          <div class="payrbodyflex2">
            <a class="payb_1" @click="openandclo('mustlogin', false)">返回</a>
            <a class="payb_2" @click="climustlogin">前往登录</a>
          </div>
        </div>
        <img
          class="login-close"
          @click="openandclo('mustlogin', false)"
          src="../../assets/img/close.png"
          alt="关闭需要用户登录弹窗"
        />
      </div>
    </div>
    <!-- 遮罩层 -->
    <div id="coverm"></div>
  </div>
</template>

<script>
let that;
let fetch;
let vuex;
import 'swiper/dist/css/swiper.min.css';
import Swiper from 'swiper';
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';

export default {
  components: {
    Mtop,
    Mbottom,
  },
  name: 'mHome',
  computed: {
    islogin: () => {
      return that.$store.state.islogin;
    },
    isnormal: () => {
      var code = true;
      console.log(that.titleindex);
      if (
        that.titleindex >= 0 &&
        that.menutotal &&
        that.menutotal.length &&
        that.menutotal[that.titleindex - 1] &&
        that.menutotal[that.titleindex - 1].name === '午夜版'
      ) {
        code = false;
      }
      return code;
    },
  },
  data() {
    return {
      ub_index: 1,
      mustlogin: false,
      loadCli: false, //是否开启加载
      hiddenData: {},
      hiddenValue: '',
      hiddenError: false,
      // hiddenWuye_text: '请输入密码',
      //弹窗开关
      hiddenPass: false,
      //这里定义哪些栏目在首页展示分类标签，跟据ID
      showClassification: [
        100, //电影
        105, //纪录片
        // 106, //粤台专区
        107, //儿童
      ],
      topDowm: false,
      loading: true, //打开加载提示
      video_enter: true,
      isloading: false, //筛选页加载
      istobottom: false, //筛选页到底
      video_list: null, //筛选的视频列表
      video_total: [], //筛选总数
      video_none: false,
      menu_act: null,
      sendcode: null, //当前滚动加载发送状态码
      titleindex: 0,
      enter: false, //动画过渡中间人
      notice_list: null, //走马灯公告
      banner_list: [], //轮播图
      menutotal: [], //排行榜和推荐
      video_latest_list: {}, //各类推荐榜数据合集
      homemenutotal: [], //顶部分类
      guanggaolist: null, //广告信息
      guanggaolist2: null, //广告信息
      filindex: null, //当前选中的分类下标
      menu_fu_act: null, //二级菜单的下标
      ccc1: {}, //带s的参数字段
      cccNight: null, //午夜版的
      cccDefault: null, //正常的
      //记录当前筛选的选值,下标值
      ccc2: {
        languages: null,
        years: null,
        regions: null,
        states: null,
        mosaic: null,
        tags: null,
      },
      title: [
        { name: '全部' },
        { name: '电影' },
        { name: '电视剧' },
        { name: '综艺' },
        { name: '动漫' },
        { name: '儿童' },
      ],
      lang: {
        languages: '语言',
        years: '年份',
        regions: '地区',
        states: '状态',
        mosaic: '打码',
        tags: '喜好',
      },
      filact: {
        parent_category_id: null,
        category_id: null,
        language: null,
        year: null,
        region: null,
        state: null,
        keyword: '',
        paid: null,
        page: null,
        pagesize: null,
        mosaic: null,
        tag: null,
      },
      fy: {
        pagenum: null,
        pagetotal: 20,
        pages: {
          page: 1,
          pagesize: 24,
        },
        mobanpage: [1, 2, 3, 4, 5, 6, 7, 8],
        pageList: [1, 2, 3, 4, 5, '...', 11, 12],
      },
      timewindowScroll: () => {},
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    this.ub_index = window.g.ub_index;
    that;
    fetch;
    vuex;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (!that.YS.ismob()) {
      //web端地址
      if (that.$route.path.indexOf('/uvod.tv') < 0) {
        // console.log(window.g.WEB_URL);
        window.location.href = window.g.ub_index == 2 ? window.g.WEB_URL_BO : window.g.WEB_URL;
      }
    }

    // // that.$route.path;
    // // debugger;
    // // 检测设备跳转
    // if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    //   //移动端地址
    //   if (that.$route.path.indexOf('/m.uvod.tv') < 0)
    //     window.location.href = 'https://www.m.uvod.tv';
    // } else {
    //   //web端地址
    //   if (that.$route.path.indexOf('/uvod.tv') < 0) window.location.href = 'https://www.uvod.tv';
    // }
    //获取轮播图缓存
    var mbannerstore = JSON.parse(localStorage.getItem('mbanner_list'));
    if (mbannerstore) {
      if (mbannerstore.time > new Date().getTime()) {
        that.banner_list = mbannerstore.data;
      }
    }
    //获取菜单存储
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore) {
      if (menutotalstore.time > new Date().getTime()) {
        // that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
        that.menutotal = menutotalstore.data;
      }
    }

    //获取广告缓存
    var guanggaostore = JSON.parse(localStorage.getItem('mguanggaolist'));
    if (guanggaostore) {
      if (guanggaostore.time > new Date().getTime()) {
        that.guanggaolist = guanggaostore.data;
      }
    }
    var guanggaostore2 = JSON.parse(localStorage.getItem('mguanggaolist2'));
    if (guanggaostore2) {
      if (guanggaostore2.time > new Date().getTime()) {
        that.guanggaolist2 = guanggaostore2.data;
      }
    }
    //获取走马灯缓存
    var marqueestore = JSON.parse(localStorage.getItem('notice_list'));
    if (marqueestore) {
      if (marqueestore.time > new Date().getTime()) {
        that.notice_list = marqueestore.data;
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          value: true,
          key2: 'lant',
        });
      }
    }
    //获取视频筛选词缓存
    // var cccstore = JSON.parse(localStorage.getItem('ccc1'));
    // if (cccstore) {
    //   if (cccstore.time > new Date().getTime()) {
    //     that.ccc1 = cccstore.data;
    //   }
    // }
    //节流
    this.timewindowScroll = this.YS.fnThrottle(this.windowScroll, 50);
  },
  destroyed() {
    window.removeEventListener('scroll', this.timewindowScroll);
  },
  mounted() {
    that.$store.commit('changelogin');
    let winhei =
      window.clientHeight || document.documentElement.clientHeight || document.body.clientHeight;
    //获取当前一开始应该加载多少个顶级菜单的推荐
    if (winhei) {
      that.sendcode = Math.ceil((winhei - 470) / 170);
      if (that.sendcode == 1) that.sendcode = 2;
    }
    window.addEventListener('scroll', this.timewindowScroll);
    that.enter = true;
    that.getbanner(); //获取轮播图数据
    that.apinoticemarquee(); //api走马灯公告
    // that.getRL(); //获取推荐信息
    that.adall('mobile-home-banner-below'); //获取广告信息
    that.adall2('mobile-home-banner-below-bottom'); //获取广告信息
    that.getvideofilter(); // 获取视频筛选词
    // setTimeout(() => {
    //   that.YS.actgo('m_top-title-list', 'm_top-title-list-act', 0);
    // }, 300);
    //获取地址栏参数
    if (Object.keys(that.$route.query).length) {
      Object.keys(that.$route.query).forEach((e) => {
        that.filact[e] = that.$route.query[e];
      });
      that.filact.page && (that.fy.pages.page = that.filact.page);
      that.filact.pagesize && (that.fy.pages.pagesize = that.filact.pagesize);
    }
    this.$nextTick(() => {
      if (that.filact.parent_category_id) {
        if (that.menutotal && that.menutotal[0]) {
          that.menutotal.forEach((e, i) => {
            if (e.id == that.filact.parent_category_id) {
              that.titleindex = i;
            }
          });
          that.climtopTitle(that.filact.parent_category_id, that.titleindex + 1);
        } else {
          that.climtopTitle(that.filact.parent_category_id, 0);
        }
      }
    });
    if (that.$route.query) {
      if (that.$route.query.go == 'hidden') {
        if (localStorage.getItem('mloginAfter_second')) {
          let data = JSON.parse(localStorage.getItem('mloginAfter_second'));
          that.climtopTitle(data.id, data.index, data.total, data.hidden);
        }
      }
    }
  },
  methods: {
    //需要登录  点击去登录
    climustlogin() {
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'none';
      var localNow = {};
      localNow.path = that.$route.path;
      localNow.query = that.$route.query;
      localNow.query.go = 'hidden';
      localStorage.setItem('mloginAfter', JSON.stringify(localNow));
      localStorage.setItem('mloginAfter_second', JSON.stringify(that.hiddenData));
      that.YS.routetogo({ path: '/mlogin' });
    },
    //弹窗开关
    openandclo(type, boo) {
      that[type] = boo;
      var coverm = document.querySelector('#coverm');
      if (coverm && coverm.style) {
        if (boo || that.mustvip) {
          coverm.style.display = 'block';
          try {
            if (!that.minimalist && that.dp) that.dp.player.fullscreen = false;
          } catch (error) {
            console.log(error.message);
          }
        } else {
          coverm.style.display = 'none';
        }
      }
    },

    //点击下拉
    cliTopDowm(titleindex) {
      if (titleindex > 0) return;
      that.topDowm = !that.topDowm;
      if (that.topDowm) {
        that.climtopTitle(0, 0);
      } else {
        // that.climtopTitle(0, -1);
        that.climtopTitle(null, 0, true);
      }
    },
    //用于图片加载完毕替换图片
    loadOver(pic, i, pid) {
      var newImg = new Image();
      newImg.src = pic;
      newImg.onload = () => {
        if (
          this.video_latest_list &&
          this.video_latest_list[pid] &&
          this.video_latest_list[pid][i]
        ) {
          // return true;
          this.video_latest_list[pid][i].pic = newImg.src;
          this.$nextTick(() => {
            that.video_latest_list[pid][i].picLoad = true;
          });
        }
      };
    },
    //用于图片加载完毕替换图片
    loadOverEasy(pic, i) {
      var newImg = new Image();
      newImg.src = pic;
      newImg.onload = () => {
        if (this.video_list && this.video_list[i]) {
          // return true;
          this.video_list[i].pic = newImg.src;
          this.$nextTick(() => {
            that.video_list[i].picLoad = true;
          });
        }
      };
    },
    //判断是否要返回分类名称
    isShowClassification(pid) {
      if (that.showClassification.indexOf(pid) >= 0) {
        return true;
      } else {
        return false;
      }
    },
    //获取分类的字段
    getClassification(pid, id) {
      if (!that.menutotal) return;
      let arrayIndex = that.menutotal.findIndex((item) => item.id == pid);
      if (arrayIndex >= 0) {
        let childIdex = that.menutotal[arrayIndex].list.findIndex((item) => item.id == id);
        if (childIdex < 0) return;
        return that.menutotal[arrayIndex].list[childIdex].name;
      } else {
        return;
      }
    },
    //点击 搜索后里面的视频
    clickSearchList(id) {
      that.YS.routetogo({
        path: '/mplay',
        query: { video_id: id },
      });
    },

    //更新跳转路由并获取视频列表
    getrou() {
      that.filact.page = that.fy.pages.page;
      that.filact.pagesize = that.fy.pages.pagesize;
      // that.YS.routetogo({
      //   path: '/fil',
      //   query: that.filact,
      // });
      that.getVideolist();
      // window.location.href = this.$route.fullPath;
    },
    //获取视频列表
    getVideolist() {
      that.video_none = false;
      that.video_list = null; //清空列表
      that.video_enter = false;
      that.loading = true;
      that.api.user.api_videolist(that.filact).then((data) => {
        that.loading = false;
        that.video_enter = true;
        that.video_list = data.video_list;
        that.video_total = data.video_total;
        that.fy.pagetotal = data.video_total;
        that.YS.jilu(that.fy);
        if (!data.video_list) {
          that.video_none = true;
          that.loading = false;
          return;
        }
        that.video_list.forEach((e, i) => {
          that.loadOverEasy(e.pic, i);
          that.video_list[i].pic = '';
        });
      });
    },
    //二次加载，push添加视频数据
    pushvideo_list() {
      that.filact.page = that.fy.pages.page;
      that.filact.pagesize = that.fy.pages.pagesize;
      that.api.user.api_videolist(that.filact).then((data) => {
        that.video_enter = true;
        data.video_list &&
          data.video_list.forEach((e) => {
            that.video_list.push(e);
          });
        that.video_list.forEach((e, i) => {
          try {
            if (that.video_list[i] && that.video_list[i].picLoad) {
              return;
            }
            that.loadOverEasy(e.pic, i);
            that.video_list[i].pic = '';
          } catch (error) {
            console.log(error);
          }
        });
        that.isloading = false;
        that.video_total = data.video_total;
        that.fy.pagetotal = data.video_total;
        that.YS.jilu(that.fy);
      });
    },
    // 获取视频筛选词
    getvideofilter() {
      let symbol = {};
      if (
        that.menutotal.findIndex(
          (item) => item.id == that.filact.parent_category_id && item.symbol === 'night',
        ) >= 0
      ) {
        symbol.category_symbol = 'night';
      }
      if (symbol.category_symbol && that.cccNight) {
        //是午夜版

        that.ccc1 = that.cccNight;
        return;
      } else if (!symbol.category_symbol && that.cccDefault) {
        that.ccc1 = that.cccDefault;
        return;
      }
      that.api.user.api_videofilter(symbol).then((data) => {
        if (symbol.category_symbol) {
          that.cccNight = data;
        } else {
          that.cccDefault = data;
        }
        that.ccc1 = data;
        // Object.keys(that.ccc2).forEach((e) => {
        //   that.ccc2[e] = that.ccc1[e].indexOf(that.filact[e]);
        // });
        //存储至本地
        // that.YS.savestore('ccc1', that.ccc1, 600);

        // this.$nextTick(() => {
        //   that.changerList(null);
        // });
      });
    },
    // 通过分类ID前往搜索
    gotofil(category_id) {
      that.$refs.mtop.msearch('', category_id);
      // that.YS.routetogo({ path: '/mplay', query: { category_id: id } });
    },
    // 获取在页面上的绝对高度
    getElementTop(element) {
      var actualTop = element.offsetTop;
      var current = element.offsetParent;

      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      document.documentElement.clientHeight || document.body.clientHeight;
      return actualTop;
    },
    //校验隐藏的密码（午夜版）
    checkHidden() {
      if (that.loadCli) return false;
      that.hiddenError = false;
      that.loadCli = true;
      that.api.user
        .api_userverifyNightPassword({ password: that.hiddenValue })
        .then(() => {
          if (that.hiddenData) {
            that.climtopTitle(
              that.hiddenData.id,
              that.hiddenData.index,
              that.hiddenData.total,
              false,
            );
            that.getvideofilter();
            that.openandclo('hiddenPass', false);
            that.$refs.mtop.messTop = '解锁成功';
            that.$refs.mtop.mess_show = true;
            setTimeout(() => {
              that.$refs.mtop.mess_show = false;
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error.message);
          that.hiddenError = true;
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    // 一级分类 / 点击大分类分栏
    climtopTitle(id, index, total, hidden, name) {
      that.hiddenData = {};
      if (hidden === 'night') {
        that.hiddenData.id = id;
        that.hiddenData.index = index;
        that.hiddenData.total = total;
        that.hiddenData.hidden = true;
        that.hiddenValue = '';
        if (!that.islogin) {
          that.openandclo('mustlogin', true);
          return;
        }

        that.openandclo('hiddenPass', true);
        return;
      }
      that.filindex = index;
      // 改变视窗高度，注释掉
      // index &&
      //   setTimeout(() => {
      //     var hei = that.getElementTop(document.getElementsByClassName('m_fil_title')[index - 1]);
      //     window.scrollTo({
      //       top: hei - 155,
      //       behavior: 'smooth',
      //     });
      //   }, 300);
      //当选中一级分类时
      if (index > 0 || !total) {
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'swipe',
          value: false,
        });
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'guanggao',
          value: false,
        });
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'guanggao2',
          value: false,
        });
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'movie',
          value: false,
        });
      } else {
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'swipe',
          value: true,
        });
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'guanggao',
          value: true,
        });
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'guanggao2',
          value: true,
        });
        that.$store.commit('changeEvery', {
          key: 'mhomeshow',
          key2: 'movie',
          value: true,
        });
      }
      that.filact.category_id = null;
      that.menu_fu_act = 0;
      that.titleindex = index;

      if (id) {
        that.filact.parent_category_id = id;
        that.$refs.mtop.filact.parent_category_id = id;
        that.$refs.mtop.titleindex = index;
        that.$refs.mtop.inputNodes = name;
      } else if (id == 0) {
        that.filact.parent_category_id = null;
      } else if (id === null) {
        that.$refs.mtop.filact.parent_category_id = null;
        that.$refs.mtop.titleindex = null;
        that.$refs.mtop.inputNodes = '';
      }
      that.filact.mosaic = '';
      that.filact.tag = '';
      that.fy.pages.page = 1;
      if (id != null) that.getrou();
      that.getvideofilter();
    },
    //二级分类点击事件
    changecategory_id(index, id) {
      //这里的index默认+1了，为了对应外面的全部按钮
      // if (index) {
      //   that.filact.category_id = id;
      // } else {
      //   that.filact.category_id = null;
      // }
      that.menu_fu_act = index;
      that.filact.category_id = id;
      that.fy.pages.page = 1;
      that.getrou();
    },
    // 小分类点击事件
    changerList(type, i) {
      //如果没传参，默认全选
      var typeAfter;
      switch (type) {
        case 'regions':
          typeAfter = 'region';
          break;
        case 'languages':
          typeAfter = 'language';
          break;
        case 'years':
          typeAfter = 'year';
          break;
        case 'states':
          typeAfter = 'state';
          break;
        case 'tags':
          typeAfter = 'tag';
          break;
        default:
          typeAfter = type;
          break;
      }
      if (!type) {
        // Object.keys(that.ccc2).forEach((e) => {
        //   // var obj = document.getElementsByClassName(e + '_fil');
        //   // that.YS.actlist(obj, that.ccc2[e] + 1, 'fil-act');
        // });
        if (type != undefined) that.filact[typeAfter] = null;
        // that.getVideolist();
        that.fy.pages.page = 1;
        that.getrou();
      } else {
        // var obj1 = document.getElementsByClassName(type + '_fil');
        that.ccc2[type] = i; //改变样式选择的下标
        that.filact[typeAfter] = that.ccc1[type][i]; //改变当前记录的选择内容
        // if (i == null) {
        //   that.YS.actlist(obj1, 0, 'fil-act');
        // } else {
        //   that.YS.actlist(obj1, that.ccc2[type] + 1, 'fil-act');
        // }
        that.fy.pages.page = 1;
        that.getrou();
      }
    },
    //api走马灯公告
    apinoticemarquee() {
      if (!that.notice_list) {
        that.api.user.api_noticemarquee({}).then((data) => {
          if (data.notice_list) {
            that.notice_list = data.notice_list;
            that.$store.commit('changeEvery', {
              key: 'mhomeshow',
              value: true,
              key2: 'lant',
            });
            //存储至本地
            that.YS.savestore('notice_list', data.notice_list, 600);
          }
        });
      }
    },
    // api获取轮播图
    getbanner() {
      if (!that.banner_list[0]) {
        that.api.user.api_banner({ platform: 2 }).then((data) => {
          that.banner_list = data.banner_list;
          //存储至本地
          that.YS.savestore('mbanner_list', data.banner_list, 600);
          this.$nextTick(() => {
            that.doswiper();
          });
        });
      } else {
        this.$nextTick(() => {
          that.doswiper();
        });
      }
    },
    //api获取推荐和排行榜
    getRL() {
      if (!that.menutotal[0]) {
        that.menutotal = [];
        that.api.user.api_categoryhome({}).then((data) => {
          data.category_list.forEach((e) => {
            if (e.pid == 0) {
              e.list = [];
              that.menutotal.push(e);
            } else {
              that.menutotal.forEach((a) => {
                if (a.id == e.pid) {
                  a.list.push(e);
                }
              });
            }
          });
          //存储至本地
          that.YS.savestore('menutotal', that.menutotal, 600);
          this.$nextTick(() => {
            for (let i = 0; i <= that.sendcode; i++) {
              that.getRecommend(that.menutotal[i].id); //获取最新的电影推荐列表
            }
          });
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i <= that.sendcode; i++) {
            that.getRecommend(that.menutotal[i].id); //获取最新的电影推荐列表
          }
        });
      }
    },
    //api获取 最新 的电影/电视剧...列表
    getRecommend(id) {
      that.api.user.api_videolatest({ parent_category_id: id }).then((data) => {
        this.$set(this.video_latest_list, id, data.video_latest_list);
        if (!data.video_latest_list) return;
        this.$nextTick(() => {
          this.video_latest_list[id].forEach((e, i) => {
            that.loadOver(e.pic, i, id);
            that.video_latest_list[id][i].pic = '';
          });
        });
      });
    },
    // 初始化/开启轮播器
    doswiper() {
      new Swiper('.swiper-container', {
        loop: true,
        // 如果需要分页器
        pagination: '.swiper-pagination',
        paginationClickable: true,
        autoplay: 3000,
        speed: 300,
        effect: 'fade',
        // effect: 'coverflow',
      });
      //绑定轮播图的点击事件
      var swiperslide = document.getElementsByClassName('swiper-slide');
      if (swiperslide) {
        swiperslide.forEach((item) => {
          item.addEventListener('click', () => {
            var url = item.getAttribute('data-url');
            window.open(url);
          });
        });
      }
    },
    //获取广告信息
    adall(symbols) {
      if (!that.guanggaolist) {
        that.api.user.api_adall({ symbols: symbols }).then((data) => {
          if (data) {
            if (that.guanggaolist == null) that.guanggaolist = {};
            that.guanggaolist[symbols] = data[symbols];
            //存储至本地
            that.YS.savestore('mguanggaolist', data[symbols], 600, symbols);
          }
        });
      }
    },
    adall2(symbols) {
      if (!that.guanggaolist2) {
        that.api.user.api_adall({ symbols: symbols }).then((data) => {
          if (data) {
            if (that.guanggaolist2 == null) that.guanggaolist2 = {};
            that.guanggaolist2[symbols] = data[symbols];
            //存储至本地
            that.YS.savestore('mguanggaolist2', data[symbols], 600, symbols);
          }
        });
      }
    },
    //点击广告
    cliguanggao(type) {
      // that.guanggaolist[type][0];
      // window.location.href = that.guanggaolist[type][0].url;
      window.open(that.guanggaolist[type][0].url);
    },
    cliguanggao2(type) {
      // that.guanggaolist[type][0];
      // window.location.href = that.guanggaolist[type][0].url;
      window.open(that.guanggaolist2[type][0].url);
    },

    // 滚动条距离页面顶部的距离
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let seeheight = document.documentElement.clientHeight || document.body.clientHeight;
      let filbox = document.getElementsByClassName('m_fil')[0].offsetHeight;
      let m_home_block = document.getElementsByClassName('m_home_block_black')[0].offsetHeight;
      let filheight = filbox + m_home_block;
      // console.log(filheight);
      // console.log(scrollTop + seeheight);
      if (scrollTop > 90) {
        that.$store.commit('changeEvery', {
          key: 'mtopfloating',
          value: true,
          key2: 'top',
        });
      } else {
        that.$store.commit('changeEvery', {
          key: 'mtopfloating',
          value: false,
          key2: 'top',
        });
      }

      //筛选页滑到下面时
      if (filbox && filheight <= scrollTop + seeheight + 200 && (that.fy.pages.page += 1)) {
        //加载第N页，并添加到里面
        // console.log(filheight);
        // console.log(scrollTop + seeheight);
        //还有需要加载的内容时
        if (that.fy.pages.page < that.fy.pagenum) {
          that.pushvideo_list();
          that.istobottom = false;
          that.isloading = true;
        }
        //已全部加载完毕时
        if (that.fy.pages.page >= that.fy.pagenum) {
          that.pushvideo_list();
          that.istobottom = true;
          that.isloading = false;
        }
      }
      // console.log(filheight);
      // console.log(scrollTop + seeheight);
      that.menutotal.forEach((e, i) => {
        if (i > that.sendcode) {
          if (scrollTop >= 170 * (i - 1)) {
            if (!that.video_latest_list[e.id] && (that.sendcode += 1)) {
              if (e.show) {
                that.getRecommend(e.id); //获取最新的电影推荐列表
              }
              // console.log(that.menutotalhome);
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scope>
.topfix1 {
  display: flex !important;
}
// .swiper-pagination {
//   display: none !important;
// }
.mswiper_img {
  max-height: 45vw;
}
</style>
