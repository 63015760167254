import Vue from 'vue';
import App from '@/views/mobile/mHome.vue';
import router from '@/router';
import store from '@/store';
import YS from '@/YS';
import '@/styles/index.scss';
import api from '@/api';
window.Hls = require('hls.js');
Vue.config.productionTip = false;
Vue.prototype.YS = YS;
Vue.prototype.api = api;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
